<div [routerLink]="['/']" class="cursor-pointer">
  <div class="w-full flex justify-center mb-2">
    <div
      class="flex rounded-full p-2 xbg-red-900 border"
      style="
        border-color: rgba(255, 255, 255, 0.05);
        background-color: white;
        /*
        -webkit-box-shadow: 0px 0px 24px 0px rgba(181, 20, 20, 1);
        -moz-box-shadow: 0px 0px 24px 0px rgba(181, 20, 20, 1);
        box-shadow: 0px 0px 24px 0px rgba(181, 20, 20, 1);
        */
        -webkit-box-shadow: 0px 0px 24px 0px rgb(255, 255, 255);
        -moz-box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 1);
      "
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.6" stroke="currentColor" class="size-8">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
      </svg>

    </div>
  </div>
  <div class="w-full text-xs flex justify-center mb-6 text-center text-gray-300">
    <p class="w-16">{{appName}}</p>
  </div>
</div>
